<template>
   <footer class="favorites-footer">
      <p class="favorites-footer__products-count">Количество товаров:&nbsp;{{ productsCount }}</p>
   </footer>
</template>

<script>
   import { mapGetters } from 'vuex';

   export default {
      name: 'FavoritesFooter',

      computed: {
         ...mapGetters('favorites', ['productsCount'])
      }
   };
</script>
